.container__header {
    text-align: center;
    margin-bottom: 25px;
}

#hr_header {
    border-color: whitesmoke;
    width: 90%;
    max-width: 1650px;
    margin-top: 20px;
    margin-bottom: 32px;
}

@media (max-width: 1536px) {
    .logo__header>img {
        height: 280px !important;
        /* max-width: 85%; */
    }
}

@media (max-width: 800px) {
    .logo__header>img {
        height: 210px !important;
        /* max-width: 85%; */
    }
}

/* .text__header>h2 {
    letter-spacing: 2px;
}

.text__header>h4 {
    letter-spacing: 4px;
} */