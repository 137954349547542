.notfound {
    text-align: center;
    margin: auto;
    height: fit-content;
    width: fit-content;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
}

#NFtitle {
    font-size: 150px;
    font-weight: bolder;
}

#NFtext {
    margin-bottom: 100px;
}

#rdc_p {
    margin-top: 500px !important;
    font-size: 20px;
    color: white !important;
}