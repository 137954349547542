#btn__menu {
    padding-top: 25px !important;
    margin-left: 15px !important;
    background-color: transparent;
    border: none;
    position: absolute;
}

#btn__user {
    padding-top: 25px !important;
    margin-right: 15px !important;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0;
}

li {
    font-size: 15px;
}

.content__sidebar {
    width: 100%;
    text-align: center;
}

.content__sidebar>img {
    height: 70px;
    margin-bottom: 30px;
}

a:hover,
a:visited,
a:link,
a:active {
    /* text-decoration: none; */
    color: gray;
    font-weight: bold;
}

.foto_usuario>img {
    border: 4px solid #333333;
    border-radius: 5px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
}

.footer__sidebar {
    font-size: 14px;
    position: absolute;
    bottom: 0 !important;
    margin-bottom: 22px;
}