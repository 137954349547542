.container__dashboard {
    flex: 1;
    width: 90%;
    max-width: 1400px;
}

.container__dashboard .p-card-body {
    background-color: #002754;
}

.container__dashboard .p-component {
    background-color: white !important;
    border: 0;
    color: black !important;
    font-weight: bold;
    white-space: nowrap;
}

.container__dashboard .p-card:hover .p-component {
    background-color: darkgray !important;
}

.p-card-content {
    padding: 0 !important;
}